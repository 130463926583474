window.dropdownHandler = function () {
  $(document).ready(function () {
    dropdownEvents();
  });

  const dropdownEvents = function () {
    const dropdowns = $('.jsDropdown');

    dropdowns.each(function () {
      const dropdown = $(this),
        dropdownSelect = dropdown.find('.jsDropdownSelect'),
        dropdownSelectText = dropdownSelect.find('.jsDropdownSelectText'),
        dropdownList = dropdown.find('.jsDropdownList'),
        dropdownListItems = dropdownList.find('.jsDropdownListItem');


      dropdownSelect.on('click', () => {
        dropdown.toggleClass('active');
        dropdown.hasClass('active') ? dropdownList.slideDown() : dropdownList.slideUp();
      })

      dropdownListItems.each(function () {
        const exportLink = $(this).attr('data-href'),
          printLink = `${exportLink}?print=true`;

        $(this).parent().on('click', () => {
          dropdownSelectText.text($(this).text());
          $('.jsPricingCompleteExport').length > 0 && $('.jsPricingCompleteExport').attr('href', exportLink);
          $('.jsPricingListSelectPDF').length > 0 && $('.jsPricingListSelectPDF').attr('data-selected', exportLink);
          $('.jsPricingCompletePrint').attr('data-url', printLink);
          dropdownListItems.each(function () {
            $(this).removeClass('selected');
          });
          $(this).addClass('selected');
          $(this).parents(dropdown).hasClass('error') ? $(this).parents(dropdown).removeClass('error') : null;
          dropdownSelect.trigger('click');
        })
      })
    })
  }
};
